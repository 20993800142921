import React from 'react';
import { Row, Col, Divider } from 'antd';
import {
  faCalendarDay,
  faFlag,
  faMapMarkedAlt,
  faRoad,
} from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import {
  GatsbyResultSet,
  GlobalData,
  JourneyStep,
  MileageReport,
} from 'models';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import { Highlight } from './highlight';
import Header from './header';
import Nav from './nav';
import Map from './map';

interface LayoutProps {
  expand?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, expand = false }) => {
  const data = useStaticQuery<{
    global: GlobalData;
    journeySteps: GatsbyResultSet<JourneyStep>;
    firstMileage: GatsbyResultSet<MileageReport>;
    lastMileage: GatsbyResultSet<MileageReport>;
  }>(graphql`
    query LayoutQuery {
      lastMileage: allStrapiMileageReport(
        limit: 1
        sort: { fields: mileage, order: DESC }
      ) {
        edges {
          node {
            mileage
          }
        }
      }
      firstMileage: allStrapiMileageReport(
        limit: 1
        sort: { fields: mileage, order: ASC }
      ) {
        edges {
          node {
            mileage
          }
        }
      }
      journeySteps: allStrapiJourneyStep(filter: {}) {
        edges {
          node {
            id: strapiId
            name
            stillThere
            to(locale: "fr", formatString: "DD MMMM YYYY")
            from(locale: "fr", formatString: "DD MMMM YYYY")
            location {
              country {
                code
                name
              }
              lat
              lng
              name
            }
          }
        }
      }
      global: strapiGlobal {
        crossed_countries {
          code
          name
        }
        defaultSeo {
          id
          metaDescription
          metaTitle
          ogType
        }
        current_step {
          locale
          id
          name
          stillThere
          to(locale: "fr")
          location {
            country {
              code
              name
            }
            lat
            lng
            name
          }
        }
        siteName
      }
    }
  `);
  const { current_step: currentStep, crossed_countries: crossedCountries } =
    data.global;
  const [journeySteps] = React.useState<JourneyStep[]>(
    data.journeySteps.edges.map((x) => x.node),
  );
  const [kilometersRidden] = React.useState<number>(
    (data.lastMileage.edges[0]?.node?.mileage || 0) -
      (data.firstMileage.edges[0]?.node?.mileage || 0),
  );

  const { daysToDeparture, departed } = React.useMemo(() => {
    const departure = new Date(`2021-09-20`);
    const now = new Date();
    const diffTime = now.getTime() - departure.getTime();

    return {
      daysToDeparture: Math.abs(Math.ceil(diffTime / (1000 * 60 * 60 * 24))),
      departed: diffTime > 0,
    };
  }, []);

  return (
    <div
      style={{
        display: `flex`,
        flexDirection: `column`,
        minHeight: `100vh`,
        background: `#f4faff`,
      }}
    >
      <CookieConsent
        overlay={false}
        location="bottom"
        buttonText="Accepter"
        enableDeclineButton
        declineButtonText="Refuser"
        // flipButtons
        style={{
          background: `rgba(0,0,0,.85)`,
          maxWidth: `500px`,
          borderRadius: `0 5px 0 0`,
        }}
        cookieName="gatsby-gdpr-google-analytics"
        buttonStyle={{ background: `#B35A18`, color: `white` }}
        declineButtonStyle={{ color: `#F1F1F1`, background: `none` }}
      >
        Ce site utilise des cookies pour mesurer l&#39;efficacité du contenu et
        vous proposer la meilleure expérience possible.
      </CookieConsent>
      <Row
        className="container"
        style={{
          flexDirection: `column`,
          minHeight: expand ? `100vh` : `50vh`,
          padding: `0`,
          position: `relative`,
          height: expand ? `100vh` : `50vh`,
        }}
      >
        <main
          className="leafletmap"
          style={
            expand
              ? {
                  position: `absolute`,
                  left: `0`,
                  right: `0`,
                  top: `0`,
                  bottom: `0`,
                  zIndex: 0,
                  background: `#495057`,
                }
              : {
                  position: `absolute`,
                  left: `0`,
                  right: `0`,
                  top: `0`,
                  height: `50vh`,
                }
          }
        >
          {currentStep && (
            <Map locations={journeySteps} currentStep={currentStep} />
          )}
        </main>
        {expand && (
          <Col
            style={{
              color: `white`,
              background: `#212429d6`,
              padding: `5px 10px`,
              borderRadius: `0px 0px 5px 5px`,
              height: `fit-content`,
            }}
          >
            <Row justify="center" align="middle" gutter={16}>
              <Col style={{ paddingBottom: `15px` }}>
                <StaticImage
                  alt="Logo motorbikepacker"
                  src="../images/logo/dark.png"
                  width={100}
                  height={100}
                  placeholder="tracedSVG"
                  style={{ zIndex: 1 }}
                />
              </Col>
            </Row>
            <Row justify="center" style={{ marginBottom: `10px` }}>
              <Col>
                <Nav linkColor="#e4dfdb" />
              </Col>
            </Row>
          </Col>
        )}

        <Col
          style={{
            alignSelf: `flex-start`,
            color: `white`,
            padding: `5px`,
          }}
        >
          <Col>
            {currentStep && (
              <Row>
                <Highlight
                  title={
                    <>
                      <ReactCountryFlag
                        style={{ marginRight: `5px` }}
                        countryCode={currentStep.location.country.code}
                      />
                      {currentStep.location.country.name}
                    </>
                  }
                  icon={faMapMarkedAlt}
                />
              </Row>
            )}
            <Row>
              <Highlight
                title={`${crossedCountries?.length} pays traversé${
                  crossedCountries?.length > 1 ? `s` : ``
                }`}
                icon={faFlag}
              />
            </Row>
            <Row>
              <Highlight
                title={`${daysToDeparture} jour${
                  daysToDeparture > 1 ? `s` : ``
                } ${departed ? `depuis` : `avant`} le départ`}
                icon={faCalendarDay}
              />
            </Row>
            <Row>
              <Highlight
                title={`${kilometersRidden} kilomètres roulés`}
                icon={faRoad}
              />
            </Row>
          </Col>
        </Col>
      </Row>
      {!expand && (
        <>
          <Header />
          <Divider />
        </>
      )}
      <div
        style={{
          margin: `auto`,
          width: `100%`,
          maxWidth: expand ? `100%` : `1200px`,
          padding: `10px 10px`,
          position: expand ? `absolute` : `relative`,
          bottom: expand ? `0px` : undefined,
        }}
      >
        {children}
      </div>
      {!expand && (
        <>
          <div style={{ flexGrow: 1 }} />
          <footer
            style={{
              background: `#495057`,
              color: `white`,
              padding: `30px 15px`,
              textAlign: `center`,
            }}
          >
            &copy;2021 - MotorbikePacker.com
          </footer>
        </>
      )}
    </div>
  );
};

export default Layout;
