import React from 'react';
import { GatsbyLinkProps, Link } from 'gatsby';

export interface NavLinkProps extends GatsbyLinkProps<any> {
  activeStyle?: any;
  exact?: boolean;
  style?: any;
}

export const NavLink: React.FC<NavLinkProps> = ({
  to,
  exact = true,
  activeStyle,
  children,
  style,
}) => (
  <Link
    to={to}
    partiallyActive={!exact}
    activeStyle={activeStyle}
    style={{
      fontFamily: `Rajdhani`,
      fontWeight: 100,
      fontSize: `19px`,
      ...style,
    }}
  >
    {children}
  </Link>
);
