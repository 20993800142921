import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { JourneyStep } from 'models';
import JourneyStepMarker from './journey-step-marker';

interface MapProps {
  locations: JourneyStep[];
  currentStep: JourneyStep;
}
const Map: React.FC<MapProps> = ({ locations, currentStep }) => {
  const center: [number, number] = React.useMemo(() => {
    if (!currentStep) return [0, 0];

    return [currentStep.location.lat, currentStep.location.lng];
  }, [currentStep]);

  if (typeof window !== `undefined`) {
    return (
      <MapContainer
        center={center}
        zoom={6}
        maxZoom={17}
        zoomControl={false}
        scrollWheelZoom
        attributionControl={false}
        style={{
          height: `100%`,
          width: `100vw`,
          zIndex: 0,
          background: `#495057`,
        }}
      >
        {/* <ZoomControl position="bottomright" zoomInText="+" zoomOutText="-" /> */}

        <TileLayer
          // url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}"
          // url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
          // url="https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
          // @ts-ignore
          apikey="choisirgeoportail"
          // @ts-ignore
          format="image/jpeg"
          // @ts-ignore
          // style="normal"
        />
        {currentStep &&
          locations.map((current) => (
            <JourneyStepMarker
              key={current.id}
              step={current}
              activeStep={currentStep}
            />
          ))}
      </MapContainer>
    );
  }
  return null;
};

export default Map;
