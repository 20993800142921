import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface HighlightProps {
  title: string | JSX.Element;
  icon: IconDefinition;
}

export const Highlight: React.FC<HighlightProps> = ({ title, icon }) => (
  <div style={{ fontSize: `15px` }}>
    <FontAwesomeIcon
      icon={icon}
      style={{
        fontSize: `15px`,
        width: `15px`,
        marginRight: `5px`,
        color: `#cecece`,
      }}
    />
    {title}
  </div>
);
