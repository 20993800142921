import React from 'react';
import { Col, Row } from 'antd';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from './navlink';

interface NavProps {
  style?: React.CSSProperties;
  linkColor?: string;
}

const Nav: React.FC<NavProps> = ({ style, linkColor }) => {
  const activeStyle = {
    borderBottom: `2px solid rgb(179, 90, 24)`,
    paddingBottom: `5px`,
    fontWeight: `bold`,
  };
  return (
    <>
      <Row
        gutter={10}
        justify="center"
        style={{
          ...style,
        }}
      >
        <Col>
          <NavLink
            to="/"
            activeStyle={activeStyle}
            style={{ color: linkColor }}
          >
            ACCUEIL
          </NavLink>
        </Col>
        <Col>
          <NavLink
            to="/blog"
            exact={false}
            activeStyle={activeStyle}
            style={{ color: linkColor }}
          >
            BLOG
          </NavLink>
        </Col>
        <Col>
          <NavLink
            to="/gallery"
            exact={false}
            activeStyle={activeStyle}
            style={{ color: linkColor }}
          >
            GALERIE
          </NavLink>
        </Col>
        <Col>
          <NavLink
            to="/a-propos"
            activeStyle={activeStyle}
            style={{ color: linkColor }}
          >
            A PROPOS
          </NavLink>
        </Col>

        {/* <Col>
        <NavLink href="/partenariat" activeStyle={activeStyle}>
          <a>PARTENARIAT</a>
        </NavLink>
      </Col>
      <Col>
        <NavLink href="/contact" activeStyle={activeStyle}>
          <a>CONTACT</a>
        </NavLink>
      </Col> */}
      </Row>
      <Row gutter={10} justify="center" style={{ marginTop: `5px` }}>
        <Col>
          <a
            target="_blank"
            href="https://instagram.com/motorbikepacker"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" color="#B35A18" />
          </a>
        </Col>
        <Col>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCb7806Tky8wdOkAwufhQ75w"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} size="lg" color="#B35A18" />
          </a>
        </Col>
      </Row>
    </>
  );
};

export default Nav;
