import React from 'react';

import { Row, Col, Typography, Affix } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import Nav from './nav';

const Header: React.FC = () => {
  const [affixed, setAffixed] = React.useState(false);

  return (
    <Affix offsetTop={0} onChange={(newState = false) => setAffixed(newState)}>
      <div
        style={{
          boxShadow: affixed ? `0px 2px 1px -2px #00000085` : `none`,
          background: affixed ? `#fffffff0` : `none`,
        }}
      >
        <Row justify="center" align="middle">
          {!affixed && (
            <Col md={affixed ? undefined : 24}>
              <div
                style={{
                  // top: "-50%",
                  textAlign: `center`,
                  top: affixed ? 0 : `-105px`,
                  // float: affixed ? "left" : "none",
                  zIndex: 2,
                  width: `fit-content`,
                  margin: `auto`,
                  position: `relative`,
                  padding: `10px`,
                  paddingBottom: `0`,
                  marginBottom: affixed ? 0 : `-105px`,
                }}
              >
                {!affixed && (
                  <div
                    style={{
                      position: `absolute`,
                      width: `100%`,
                      height: `100%`,
                      left: 0,
                      top: 0,
                      right: 0,
                      borderRadius: `999px`,
                      background: `#f4faff`,
                      zIndex: -1,
                    }}
                  />
                )}
                <StaticImage
                  alt="Logo motorbikepacker"
                  src="../images/logo/dark.png"
                  width={200}
                  height={200}
                  placeholder="tracedSVG"
                  style={{ zIndex: 1 }}
                />
                {/* <img
                  // loading="eager"
                  src={mbpkLogoDark}
                  width="200px"
                  height="200px"
                  style={{ zIndex: 1 }}
                  alt="Motorbikepacker logo"
                  // priority
                /> */}
              </div>
            </Col>
          )}
          {affixed && (
            <Col>
              {/* <div style={{ height: "50px", width: "100px" }}>
                <Image
                  loading="eager"
                  priority
                  src={mbpkLogoFontOnly}
                  // width={"60px"}
                  // height={affixed ? "60px" : "200px"}
                  layout="fill"
                  objectFit="contain"
                  alt="Motorbikepacker logo"
                />
              </div> */}
              <Typography.Paragraph
                style={{
                  paddingTop: `10px`,
                  margin: `auto`,
                  color: `#6B5544`,
                  textAlign: `center`,
                  transition: `all .3s ease-in-out`,
                  fontFamily: `Recursive`,
                  fontSize: `28px`,
                  lineHeight: `28px`,
                  fontWeight: 900,
                }}
              >
                MOTORBIKE
              </Typography.Paragraph>
              <Typography.Paragraph
                style={{
                  margin: `auto`,
                  color: `#867669`,
                  textAlign: `center`,
                  transition: `all .3s ease-in-out`,
                  fontFamily: `Recursive`,
                  fontSize: `20px`,
                  lineHeight: `20px`,
                  fontWeight: 400,
                }}
              >
                PACKER
              </Typography.Paragraph>
            </Col>
          )}
        </Row>
        <Row justify="center" align="middle">
          <Col md={24}>
            <div
              style={{
                // width: "100%",
                // maxWidth: expand ? "100%" : "1200px",
                padding: `10px 20px`,
                position: `relative`,
                bottom: `0px`,
              }}
            >
              <Nav
                style={{
                  transition: `all .3s ease-in-out`,
                  margin: `auto`,
                  // margin: "auto",
                  width: `fit-content`,
                  padding: `8px 0`,
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Affix>
  );
};

export default Header;
