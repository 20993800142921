import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { DivIcon } from 'leaflet';
import { PuffLoader } from 'react-spinners';
import { JourneyStep } from 'models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface JourneyStepMarkerProps {
  step: JourneyStep;
  activeStep: JourneyStep;
}
const isDatePast = (toCheck: Date) => {
  const now = new Date();
  now.setHours(23, 59, 59, 999);
  return toCheck < now;
};
const JourneyStepMarker: React.FC<JourneyStepMarkerProps> = ({
  step,
  activeStep,
}) => {
  const isFuture = React.useMemo(
    () => new Date(step.from) > new Date(),
    [step],
  );
  const isPast = React.useMemo(() => isDatePast(new Date(step.to)), [step]);
  const dotStyle = React.useMemo(() => {
    if (isFuture) {
      return {
        border: `5px solid ${isFuture ? `#65a57c` : `#ffb91d`}`,
        background: `#efefef`,
      };
    }
    if (isPast) {
      return {
        border: `5px solid #656fa5`,
        background: `#99a2e6`,
      };
    }
    return {
      border: `5px solid  #ffb91d`,
      background: `white`,
    };
  }, [isFuture, isPast]);

  return (
    <Marker
      position={[step.location.lat, step.location.lng]}
      draggable={false}
      icon={
        new DivIcon({
          className: `animatedIcon`,
          html: ReactDOMServer.renderToString(
            <>
              {activeStep.id === step.id && (
                <div
                  style={{
                    position: `absolute`,
                    left: `-50px`,
                    top: `-20px`,
                    width: `100px`,
                    height: `100px`,
                  }}
                >
                  <PuffLoader color="#B35A18" size={100} />
                </div>
              )}
              {!isPast && (
                <div
                  style={{
                    width: `fit-content`,
                    position: `relative`,
                    left: `-5px`,
                    bottom: `10px`,
                  }}
                >
                  <ReactCountryFlag
                    style={{
                      fontSize: `30px`,
                      marginRight: `5px`,
                    }}
                    countryCode={step.location.country.code}
                  />
                </div>
              )}
              <div
                style={{
                  borderRadius: `9999px`,
                  position: `relative`,
                  left: `-10px`,
                  top: `-10px`,
                  width: `20px`,
                  height: `20px`,
                  ...dotStyle,
                }}
              />

              <div
                style={{
                  color: isPast ? `#acaebf` : `white`,
                  left: `-75px`,
                  position: `absolute`,
                  textAlign: `center`,
                  width: `150px`,
                }}
              >
                <div>{step.location.name}</div>
                {isFuture && step.from && (
                  <div>
                    <FontAwesomeIcon
                      icon={faCalendarCheck}
                      style={{
                        fontSize: `10px`,
                        width: `10px`,
                        marginRight: `5px`,
                        color: `#cecece`,
                      }}
                    />
                    {step.from}
                  </div>
                )}
              </div>
            </>,
          ),
        })
      }
    >
      {/* <Popup>{current.location.name}</Popup> */}
    </Marker>
  );
};

export default JourneyStepMarker;
